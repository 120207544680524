<template>
  <div class="admin_center">
    <Header />
    <div class="userInfo">
      <img :src="$store.state.userInfo.avatar" alt />
      <p>{{$store.state.userInfo.name}}</p>
      <div class="btn_box">
        <button class="btn surplus">余额:{{user_credit/100}}</button>
        <router-link to='mermber_recharge'>
          <button class="btn go">充值</button>
        </router-link>
      </div>
    </div>
    <div class="cell_box">
      <router-link to="/preview">
        <van-cell title="我的产品" is-link />
      </router-link>
      <router-link to="/address">
        <van-cell title="配送地址" is-link />
      </router-link>
       <router-link to="/certification">
        <van-cell title="实名认证" is-link />
      </router-link>
      <router-link to="/share">
        <van-cell title="分享给好友" is-link />
      </router-link>
      <router-link to="/change_pwd">
        <van-cell title="修改密码" is-link />
      </router-link>
      <router-link to="/email_bind">
        <van-cell title="绑定邮箱" is-link />
      </router-link>
      <router-link to="/myorder">
        <van-cell title="我的订单" is-link />
      </router-link>
      <router-link to="/record">
        <van-cell title="分销记录" is-link />
      </router-link>
      <router-link to="/wiki">
        <van-cell title="帮助文档" is-link />
      </router-link>
    </div>
    <div class="logo_out">
      <el-button style="width:100%" @click="logout">退出登录</el-button>
    </div>
  </div>
</template>
<script>
import Header from "./Header";
import { logoutApi, getWalletInfoApi } from "api/AdminCenter";
export default {
  components: {
    Header
  },
  data() {
    return {
      user_credit: ""
    };
  },
  methods: {
    logout() {
      logoutApi().then(res => {
        if (res.code == 0) {
          sessionStorage.removeItem("token");
          this.$store.dispatch({
            type: "changeLoginStatus",
            login: false
          });
          window.location.href = "/";
        }
      });
    }
  },
  mounted() {
    //获取钱包信息
    getWalletInfoApi().then(res => {
      if (res.code == 0) {
        const { user_credit } = res.data;
        this.user_credit = user_credit;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.userInfo {
  text-align: center;
  img {
    margin-top: 30px;
  }
  p {
    margin: 16px 0;
    font-size: 26px;
    color: #202020;
  }
  .btn_box {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    .btn {
      width: 114px;
      height: 36px;
      background: #517cfc;
      border-radius: 4px;
      border: 1px solid #517cfc;
      color: #fff;
      font-size: 16px;
    }
    .surplus {
      margin-right: 13px;
      background: #fff;
      color: #517cfc;
    }
  }
}
.cell_box {
  .van-cell {
    padding: 18px 16px;
    border-bottom: 1px solid #bcc5d4;
  }
}
.logo_out {
  padding: 0 16px;
  margin: 30px 0;
}
</style>